import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { QueryOptions } from '~/src/types/QueryOptions'
import type { paths } from '~/src/api/schema'
import { GET_CART } from '~/composables/queries/useCarts'
import { GET_CLIENT_USER } from '~/composables/queries/useClientUser'

const POST_CART_ITEMS = '/api/cart_items'
const PATCH_CART_ITEM = '/api/cart_items/{id}'
const DELETE_CART_ITEM = '/api/cart_items/{id}'
const CHECKOUT = '/api/cart_items/checkout'
const BATCH = '/api/cart_items/batch_create'

export function postCartItem() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      POST_CART_ITEMS,
    ],
    mutationFn: async ({ body, invalidateUser = false }: { body: QueryOptions<paths[typeof POST_CART_ITEMS]['post']>['body'], invalidateUser?: boolean }) => {
      const client = useApi()
      const { data } = await client.POST(POST_CART_ITEMS, {
        body,
        headers: { 'Content-Type': 'application/ld+json' },
      })
      if (invalidateUser) {
        await queryClient.invalidateQueries({ queryKey: [GET_CLIENT_USER] })
      }
      await queryClient.invalidateQueries({ queryKey: [GET_CART] })
      return data
    },
  })
}

export function postCartItemsByBatch() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      BATCH,
    ],
    mutationFn: async ({ body, invalidateUser = false }: { body: QueryOptions<paths[typeof BATCH]['post']>['body'], invalidateUser?: boolean }) => {
      const client = useApi()
      const { data } = await client.POST(BATCH, {
        body,
        headers: { 'Content-Type': 'application/ld+json' },
      })
      if (invalidateUser) {
        await queryClient.invalidateQueries({ queryKey: [GET_CLIENT_USER] })
      }
      await queryClient.invalidateQueries({ queryKey: [GET_CART] })
      return data
    },
  })
}

export function patchCartItem() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      PATCH_CART_ITEM,
    ],
    mutationFn: async ({ params, body }: { params: QueryOptions<paths[typeof PATCH_CART_ITEM]['patch']>['params'], body: QueryOptions<paths[typeof PATCH_CART_ITEM]['patch']>['body'] }) => {
      const client = useApi()
      const { data } = await client.PATCH(PATCH_CART_ITEM, {
        params,
        body,
        headers: { 'Content-Type': 'application/merge-patch+json' },
      })
      await queryClient.invalidateQueries({ queryKey: [GET_CART] })
      return data
    },
  })
}

export function deleteCartItem() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      DELETE_CART_ITEM,
    ],
    mutationFn: async ({ params }: { params: QueryOptions<paths[typeof DELETE_CART_ITEM]['delete']>['params'] }) => {
      const client = useApi()
      const { data } = await client.DELETE(DELETE_CART_ITEM, {
        params,
        headers: { 'Content-Type': 'application/merge-patch+json' },
      })
      await queryClient.invalidateQueries({ queryKey: [GET_CART] })
      return data
    },
  })
}

export function checkoutCart() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      CHECKOUT,
    ],
    mutationFn: async ({ body }: { body: QueryOptions<paths[typeof CHECKOUT]['post']>['body'] }) => {
      const client = useApi()
      const { data } = await client.POST(CHECKOUT, {
        body,
        headers: { 'Content-Type': 'application/ld+json' },
      })
      await queryClient.invalidateQueries({ queryKey: [GET_CLIENT_USER] })
      return data
    },
  })
}
